import * as Msdyn365 from '@msdyn365-commerce/core';
import { debounce } from 'lodash';

import { generateImageUrl, getProductPageUrlSync } from '@msdyn365-commerce-modules/retail-actions';

import React, { RefObject, useEffect, useRef, useState } from 'react';
import { IHeaderSectionViewProps } from './header-section';
import { CategoryHierarchy } from '@msdyn365-commerce/commerce-entities';
import { Cart } from '@msdyn365-commerce/retail-proxy';
import { ISiteOptionListData } from './header-section.props.autogenerated';
import { logDetails } from '../../utils/debug-mode';

const SubSubMenuComponent: React.FC<{ subSubMenuCategories: CategoryHierarchy[] }> = ({ subSubMenuCategories }) => {
    return (
        <ul className={` ${subSubMenuCategories.length > 0 ? 'open' : ''}`}>
            {subSubMenuCategories.map(subSubMenuCategory => (
                <li key={subSubMenuCategory.RecordId} className='Menu-Item-2'>
                    <a href={subSubMenuCategory.Url} className='Menu-Link-2'>
                        {subSubMenuCategory.Name}
                    </a>
                </li>
            ))}
        </ul>
    );
};

const SubMenuComponent: React.FC<{
    subCategories: CategoryHierarchy[];
    isOpen: boolean;
    onToggle: () => void;
    onSubSubMenuToggle: (subCategory: CategoryHierarchy, event: React.MouseEvent) => void;
}> = ({ subCategories, isOpen, onToggle, onSubSubMenuToggle }) => {
    return (
        <ul className={`submenu ${isOpen ? 'open' : ''}`}>
            {subCategories.map(subCategory => (
                <li key={subCategory.RecordId} className='Menu-Item-1'>
                    <div className='Menu-ItemContent'>
                        <a href={subCategory.Url} className='Menu-Link-1' onClick={e => onSubSubMenuToggle(subCategory, e)}>
                            {subCategory.Name}
                        </a>
                        {subCategory.Children && subCategory.Children.length > 0 && (
                            <div className='Menu-Icon-1' onClick={onToggle}>
                                {/* ▼ */}
                            </div>
                        )}
                    </div>
                    {isOpen && <SubSubMenuComponent subSubMenuCategories={subCategory.Children || []} />}
                </li>
            ))}
        </ul>
    );
};
export default (props: IHeaderSectionViewProps) => {
    logDetails(props.context.app.config.debugMode, props);
    const { inputChange, suggestions, categories, config, cart } = props;

    // @ts-ignore
    const cartState: Cart = typeof cart !== 'undefined' ? cart._cart : undefined;
    const user = props.context.request.user;
    // const intlFormatter = props.context.cultureFormatter;
    const context = props.context.actionContext;
    const subCategoryRef = useRef<HTMLDivElement>(null);
    const menuRef = useRef<HTMLAnchorElement>(null);
    const miniCartRef = useRef<HTMLButtonElement>(null);
    const myAccountRef = useRef<HTMLButtonElement>(null);

    const [openStoreSwitcher, setStoreSwitcher] = React.useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [searchResultsOpen, setSearchResultsOpen] = useState(false);
    const [miniCartOpen, setMiniCartOpen] = useState(false);
    miniCartOpen;
    const [myAccountOpen, setMyAccountOpen] = useState(false);
    const [searchInput, setSearchInput] = useState('');

    const LogoImageGrid: Msdyn365.IGridSettings = {
        xs: {
            w: 0,
            h: 0
        },
        sm: {
            w: 0
        },
        md: {
            w: 0
        },
        lg: {
            w: 0
        },
        xl: {
            w: 0
        }
    };

    LogoImageGrid;
    useEffect(() => {
        if (searchInput.length > 0) {
            setSearchResultsOpen(true);
            inputChange(searchInput, context);
        } else {
            setSearchResultsOpen(false);
        }
    }, [searchInput]);

    const updateSearchInput = (inputValue: string) => {
        setSearchInput(inputValue);
    };
    const debounceOnChange = debounce(updateSearchInput, 500);
    const searchInputRef: RefObject<HTMLInputElement> = useRef(null);

    useEffect(() => {
        if (!menuOpen) {
            if (Msdyn365.msdyn365Commerce.isBrowser) {
                document.querySelectorAll('.Menu-Link').forEach(el => {
                    el.classList.remove('Menu-Link_isHovered');
                });
            }
        }
    }, [menuOpen]);

    const handleMenuOpen = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.currentTarget.classList.add('Menu-Link_isHovered');
        setMenuOpen(true);

        if (Msdyn365.msdyn365Commerce.isBrowser) {
            document.querySelectorAll('.Menu-Link').forEach(el => {
                if (el !== e.currentTarget) {
                    el.classList.remove('Menu-Link_isHovered');
                }
            });
        }
    };
    const [menuContainerOpen, setMenuContainerOpen] = useState(false);
    const [currentSubMenuOpen, setCurrentSubMenuOpen] = useState<number | null>(null);

    const [currentSubCategories, setCurrentSubCategories] = useState<CategoryHierarchy[]>([]);
    // @ts-ignore
    const [currentSubSubMenuCategories, setCurrentSubSubMenuCategories] = useState<CategoryHierarchy[]>([]);
    // @ts-ignore
    const [currentSubSubMenuOpen, setCurrentSubSubMenuOpen] = useState<number | null>(null);
    // @ts-ignore
    const [subSubMenuPosition, setSubSubMenuPosition] = useState({ top: 0, left: 0 });
    const [openSubMenuIds, setOpenSubMenuIds] = useState<number[]>([]);
    // @ts-ignore
    const [openSubSubMenuIds, setOpenSubSubMenuIds] = useState<number[]>([]);
    if (Msdyn365.msdyn365Commerce.isBrowser) {
        document.addEventListener('click', e => {
            if (menuRef.current && !menuRef.current.contains(e.target as Node) && !subCategoryRef.current?.contains(e.target as Node)) {
                setMenuOpen(false);
                document.querySelectorAll('.Menu-Link').forEach(el => {
                    el.classList.remove('Menu-Link_isHovered');
                });
            }

            if (miniCartRef.current && !miniCartRef.current.contains(e.target as Node)) {
                setMiniCartOpen(false);
            }

            if (myAccountRef.current && !myAccountRef.current.contains(e.target as Node)) {
                setMyAccountOpen(false);
            }
        });
    }

    const handleSubMenuToggle = (categoryId: number, event: React.MouseEvent) => {
        const isSameCategory = openSubMenuIds.includes(categoryId);

        setOpenSubMenuIds(prevIds => (isSameCategory ? prevIds.filter(id => id !== categoryId) : [...prevIds, categoryId]));
        setCurrentSubMenuOpen(isSameCategory ? null : categoryId);
        // setCurrentSubSubMenuOpen(null);
        console.log('sub smenu id', categoryId);
        // Calculate position for sub-sub-menu based on the position of the triggering element
        const rect = event.currentTarget.getBoundingClientRect();
        setSubSubMenuPosition({ top: rect.top, left: rect.right });
    };

    const handleSubSubMenuToggle = (category: CategoryHierarchy, event: React.MouseEvent) => {
        setOpenSubSubMenuIds(prevIds =>
            prevIds.includes(category.RecordId) ? prevIds.filter(id => id !== category.RecordId) : [...prevIds, category.RecordId]
        );

        setCurrentSubSubMenuOpen(prev => (prev === category.RecordId ? null : category.RecordId));
        console.log('sub sub sub menu id', category.RecordId);

        // Calculate position for sub-sub-menu based on the position of the triggering element
        const rect = event.currentTarget.getBoundingClientRect();
        setSubSubMenuPosition({ top: rect.top, left: rect.right });
    };
    const toggleMenu = () => {
        setMenuContainerOpen(!menuContainerOpen);
        setCurrentSubMenuOpen(null);
        setCurrentSubSubMenuOpen(null);
    };
    const handleMenuCategoryClose = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e;
        if (Msdyn365.msdyn365Commerce.isBrowser) {
            document.querySelectorAll('.Menu-Link').forEach(el => {
                el.classList.remove('Menu-Link_isHovered');
            });
        }
        setMenuOpen(false);
    };

    const handleOpenMyAccount = () => {
        if (user && user.isAuthenticated) {
            setMyAccountOpen(true);
        } else if (Msdyn365.msdyn365Commerce.isBrowser) {
            window.location.href = user.signInUrl as string;
        }
    };

    const getImageUrl = (imageUrl: string | undefined) => {
        return generateImageUrl(imageUrl, props.context.request.apiSettings) as string;
    };

    const getProductPageUrl = (name: string | undefined, recordId: string | number | undefined) => {
        return getProductPageUrlSync(name as string, recordId as number, context);
    };

    const getCurrentSubCategories = (category: CategoryHierarchy) => {
        setCurrentSubCategories(category.Children);
    };

    const [siteOption, setSiteOption] = useState<ISiteOptionListData | undefined>();
    useEffect(() => {
        props.config.siteOptions &&
            props.config.siteOptions.length > 0 &&
            props.config.siteOptions.map(value => {
                if (value.link && value.link.trim() === '/' && props.context.request.sitePath === '') {
                    setSiteOption(value);
                } else if (value.link && value.link.trim() === props.context.request.sitePath) {
                    setSiteOption(value);
                }
            });
    }, []);
    // const [mobSearchOpen, setMobSearchOpen] = useState(false);

    // const toggleSearchOpen = () => {
    //     setMobSearchOpen(!mobSearchOpen);
    // };
    return (
        <div className='Morland-Header'>
            <section className='Header-Wrapper'>
                <header className='Header Header_name_cart_overlay FixedElement-Top'>
                    <div className='SiteWideMessageCMS'>
                        <div className='CmsBlock-Wrapper CmsBlock-Wrapper_type_'>
                            <div className='row-full-width-inner' data-element='inner'>
                                <div
                                    className='pagebuilder-column-group'
                                    data-content-type='column-group'
                                    data-grid-size={12}
                                    data-element='main'
                                    style={{ display: 'flex', background: '#00296B' }}
                                >
                                    {config.topBanners &&
                                        config.topBanners.length > 0 &&
                                        config.topBanners.map((banner, index) => {
                                            return (
                                                <div
                                                    className='ml-col-6'
                                                    key={index}
                                                    data-content-type='html'
                                                    data-appearance='default'
                                                    data-element='main'
                                                    style={{ width: '50%' }}
                                                >
                                                    <div className='link'>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <a href={banner.link?.destinationUrl}>{banner.text}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='Header-MainWrapper'>
                        <div className='Header-Logo'>
                            <a
                                aria-label='Go to homepage by clicking on logo'
                                aria-hidden='false'
                                tabIndex={0}
                                href={props.app.routes.home.destinationUrl}
                                className=' Header-LogoWrapper Header-LogoWrapper_isVisible'
                            >
                                <div className='Image Image_imageStatus_1 Logo'>
                                    {/* <Msdyn365.Image
                                        className='Image-Image'
                                        gridSettings={LogoImageGrid}
                                        src={config.companyMainlogo && config.companyMainlogo.src}
                                        altText={config.companyMainlogo && config.companyMainlogo.altText}
                                        title={config.companyMainlogo && config.companyMainlogo.title}
                                    /> */}
                                    {config.companyMainlogo ? (
                                        <img
                                            src={config.companyMainlogo.src}
                                            alt={config.companyMainlogo.altText}
                                            title={config.companyMainlogo.title}
                                            className='Image-Image'
                                        />
                                    ) : null}
                                </div>
                            </a>
                        </div>
                        <div style={{ width: '100%' }}>
                            <div className='SearchBlock'>
                                <div className='SearchBlock-Wrapper'>
                                    <div className='SearchField SearchField_isVisible SearchField_isActive'>
                                        <div className='SearchField-Wrapper'>
                                            <div className='SearchField-SearchWrapper SearchField-SearchWrapper_isVisible'>
                                                <div className='SearchField-SearchInnerWrapper'>
                                                    <div className='Image Image_ratio_custom Image_imageStatus_1 Image_hasSrc SearchField-MorlandIcon '>
                                                        {config.searchBarLogo ? (
                                                            <img
                                                                src={config.searchBarLogo.src}
                                                                alt={config.searchBarLogo.altText}
                                                                className='Image-Image'
                                                                style={{ width: '100%', height: '100%' }}
                                                            />
                                                        ) : null}
                                                    </div>
                                                    <input
                                                        ref={searchInputRef}
                                                        id='search-field'
                                                        autoComplete='off'
                                                        placeholder={config.searchPlaceholder}
                                                        className='SearchField-Input SearchField-Input_isActive'
                                                        onChange={e => debounceOnChange(e.target.value)}
                                                    />
                                                    <div
                                                        role='button'
                                                        tabIndex={0}
                                                        aria-label='Search'
                                                        className='SearchField-SearchIcon'
                                                    />

                                                    <article aria-label='Search results' className='SearchOverlay-Results'>
                                                        {searchResultsOpen ? (
                                                            <div className='KlevuSearchOverlay'>
                                                                {/* <div className='KlevuSearchOverlay-leftSettingsWrapper'>
                                                                    <div className='KlevuSearchOverlay-Header'>
                                                                        <div className='KlevuSearchOverlay-Heading'>
                                                                            Suggestions (search terms)
                                                                        </div>
                                                                        <ul>
                                                                            <li>
                                                                                <a
                                                                                    href='/search/seal'
                                                                                    className=' KlevuSearchOverlay-Suggestion'
                                                                                >
                                                                                    <b>s</b>eal
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a
                                                                                    href='/search/slate'
                                                                                    className=' KlevuSearchOverlay-Suggestion'
                                                                                >
                                                                                    <b>s</b>late
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a
                                                                                    href='/search/silver'
                                                                                    className=' KlevuSearchOverlay-Suggestion'
                                                                                >
                                                                                    <b>s</b>ilver
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a
                                                                                    href='/search/seal 27x22x1800mm'
                                                                                    className=' KlevuSearchOverlay-Suggestion'
                                                                                >
                                                                                    <b>s</b>eal 27x22x1800mm
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a
                                                                                    href='/search/seal 27x22x1800mm lengths'
                                                                                    className=' KlevuSearchOverlay-Suggestion'
                                                                                >
                                                                                    <b>s</b>eal 27x22x1800mm lengths
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className='KlevuSearchOverlay-Header'>
                                                                        <div className='KlevuSearchOverlay-Heading'>Categories</div>
                                                                        <ul>
                                                                            <li>
                                                                                <a
                                                                                    href='/shop-by-product/laminate-sheets.html'
                                                                                    className=' '
                                                                                >
                                                                                    Laminate Sheets
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a
                                                                                    href='/shop-by-brand/egger/st16-matex-plaster.html'
                                                                                    className=' '
                                                                                >
                                                                                    ST16 Matex Plaster
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a
                                                                                    href='/shop-by-colour/patterns/marble-slate.html'
                                                                                    className=' '
                                                                                >
                                                                                    Marble &amp; Slate
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href='/shop-by-texture/smooth.html' className=' '>
                                                                                    Smooth
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href='/shop-by-product.html' className=' '>
                                                                                    Shop by Product
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a
                                                                                    href='/shop-by-brand/egger/st10-deepskin-rough.html'
                                                                                    className=' '
                                                                                >
                                                                                    ST10 Deepskin Rough
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a
                                                                                    href='/shop-by-brand/egger/st9-smoothtouch-matt.html'
                                                                                    className=' '
                                                                                >
                                                                                    ST9 Smoothtouch Matt
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a
                                                                                    href='/shop-by-brand/egger/st87-matex-ceramic.html'
                                                                                    className=' '
                                                                                >
                                                                                    ST87 Matex Ceramic
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a
                                                                                    href='/shop-by-brand/egger/st12-omnipore-matt.html'
                                                                                    className=' '
                                                                                >
                                                                                    ST12 Omnipore Matt
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a
                                                                                    href='/shop-by-brand/egger/st2-smoothtouch-pearl.html'
                                                                                    className=' '
                                                                                >
                                                                                    ST2 Smoothtouch Pearl
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className='KlevuSearchOverlay-Header'>
                                                                        <div className='KlevuSearchOverlay-Heading'>Pages</div>
                                                                        <ul>
                                                                            <li>
                                                                                <a
                                                                                    href='https://shop.morlanduk.com/marketing-sign-up'
                                                                                    className=' '
                                                                                >
                                                                                    Marketing Sign Up
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a
                                                                                    href='https://shop.morlanduk.com/privacy-policy'
                                                                                    className=' '
                                                                                >
                                                                                    Privacy Policy
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a
                                                                                    href='https://shop.morlanduk.com/cookie-policy'
                                                                                    className=' '
                                                                                >
                                                                                    Cookie Policy
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a
                                                                                    href='https://shop.morlanduk.com/sustainable-wood'
                                                                                    className=' '
                                                                                >
                                                                                    Sustainable
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a
                                                                                    href='https://shop.morlanduk.com/private-sales'
                                                                                    className=' '
                                                                                >
                                                                                    Welcome to our Exclusive Online Store
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a
                                                                                    href='https://shop.morlanduk.com/free-samples'
                                                                                    className=' '
                                                                                >
                                                                                    Free Samples
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a
                                                                                    href='https://shop.morlanduk.com/new-website-launch'
                                                                                    className=' '
                                                                                >
                                                                                    New website launch
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href='https://shop.morlanduk.com/bulk-buy' className=' '>
                                                                                    Bulk Buy
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a
                                                                                    href='https://shop.morlanduk.com/terms-and-conditions'
                                                                                    className=' '
                                                                                >
                                                                                    Terms &amp; Conditions
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a
                                                                                    href='https://shop.morlanduk.com/inspiration'
                                                                                    className=' '
                                                                                >
                                                                                    Design Inspiration for Your Van Build
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div> */}
                                                                <div className='KlevuSearchOverlay-Products'>
                                                                    <ul className='KlevuSearchOverlay-ProductsResult'>
                                                                        {suggestions &&
                                                                            suggestions.AllSearchResults?.map((item, index) => {
                                                                                return (
                                                                                    <li
                                                                                        key={index}
                                                                                        className='KlevuSearchOverlayProductCard-ProductCardContainer'
                                                                                    >
                                                                                        <a
                                                                                            href={getProductPageUrl(item.Value, item.Id)}
                                                                                            className=' KlevuSearchOverlayProductCard-ProductCard'
                                                                                        >
                                                                                            <div className='KlevuSearchOverlayProductCard-ProductImageWrapper'>
                                                                                                <div className='Image Image_ratio_square Image_imageStatus_1 Image_hasSrc KlevuSearchOverlayProductCard-ProductImage '>
                                                                                                    <img
                                                                                                        src={getImageUrl(item.ImageUrl)}
                                                                                                        alt={item.Value}
                                                                                                        className='Image-Image'
                                                                                                        style={{
                                                                                                            width: '100%',
                                                                                                            height: '100%'
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='KlevuSearchOverlayProductCard-ProductInformation'>
                                                                                                <span className='KlevuSearchOverlayProductCard-ProductName'>
                                                                                                    {item.Value}
                                                                                                </span>
                                                                                            </div>
                                                                                        </a>
                                                                                    </li>
                                                                                );
                                                                            })}
                                                                    </ul>
                                                                    {typeof suggestions?.AllSearchResults === 'undefined' ? (
                                                                        <span className='KlevuSearchOverlay-NoResult'>
                                                                            Were Sorry, No result found for this term
                                                                        </span>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        ) : null}
                                                    </article>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <nav className='Header-Nav'>
                            <div className='Header-CorporateWrapper'>
                                <a
                                    aria-label='Corporate'
                                    title='Corporate Site'
                                    tabIndex={0}
                                    href='https://www.ecobatbattery.com/'
                                    target='_blank'
                                    rel='noreferrer'
                                    className=' Header-Corporate Header-Corporate_isVisible_ header-icons'
                                >
                                    <span aria-hidden='true' className='Header-CorporateText'>
                                        Corporate
                                    </span>
                                </a>
                            </div>
                            <button
                                aria-label='Cancel changes'
                                aria-hidden='true'
                                tabIndex={-1}
                                className='Header-Button Header-Button_type_cancel'
                            >
                                Cancel
                            </button>
                            <button
                                aria-label='Go back'
                                aria-hidden='true'
                                tabIndex={-1}
                                className='Header-Button Header-Button_type_back'
                            />
                            <button
                                aria-label='Close'
                                aria-hidden='true'
                                tabIndex={-1}
                                className='Header-Button Header-Button_type_close'
                            />
                            <button aria-label='Share' aria-hidden='true' className='Header-Button Header-Button_type_share' />
                            <h1 className='Header-Title Header-Title_isVisible'>0 Items</h1>
                            <div aria-label='My account' className='Header-MyAccount'>
                                <button
                                    title='My Account'
                                    // ref={myAccountRef}
                                    tabIndex={0}
                                    aria-label='Open my account'
                                    id='myAccount'
                                    className='Header-MyAccountWrapper header-icons'
                                    onClick={handleOpenMyAccount}
                                >
                                    {user.isAuthenticated && <span>&nbsp;&nbsp;{user.name}</span>}
                                </button>

                                {myAccountOpen ? (
                                    <div className='Overlay Overlay_isVisible MyAccount_Overlay'>
                                        <div className='SubLinks'>
                                            {props.config.accountLinks &&
                                                props.config.accountLinks.map((link, index) => {
                                                    return (
                                                        <a key={index} href={link.link?.destinationUrl}>
                                                            {link.text}
                                                        </a>
                                                    );
                                                })}
                                            <a href={user.signOutUrl}>Sign Out</a>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            {props.slots.cartIcon}
                            <div aria-label='My account' className='Header-MyAccount'>
                                <div className='Header-SiteImage'>
                                    <img src={siteOption?.image?.src} alt={siteOption?.text} />
                                    {/* {console.log('siteOption', siteOption)} */}
                                </div>
                            </div>
                            <button
                                aria-label='Clear'
                                aria-hidden='true'
                                tabIndex={-1}
                                className='Header-Button Header-Button_type_clear Header-Button_isDisabled'
                            />
                            <button aria-label='Edit' aria-hidden='true' tabIndex={-1} className='Header-Button Header-Button_type_edit'>
                                Edit
                            </button>
                            <button
                                aria-label='Save changes'
                                aria-hidden='true'
                                tabIndex={-1}
                                className='Header-Button Header-Button_type_ok'
                            >
                                OK
                            </button>
                        </nav>
                    </div>
                    {/* <div className='Header-MainWrapper'>
                        <div className='Menu-MenuWrapper'>
                            <div className='Menu-MainCategories'>
                                <ul aria-label='All' className='Menu-ItemList Menu-ItemList_type_main'>
                                    {categories &&
                                        categories.map(category => {
                                            return (
                                                <li key={category.RecordId} className='Menu-Item'>
                                                    <a
                                                        href={category.Url}
                                                        className=' Menu-Link '
                                                        onMouseEnter={e => {
                                                            if (category.Children && category.Children.length > 0) {
                                                                handleMenuOpen(e);
                                                                getCurrentSubCategories(category);
                                                            } else {
                                                                setMenuOpen(false);
                                                            }
                                                        }}
                                                        ref={menuRef}
                                                    >
                                                        <figure className='Menu-ItemFigure Menu-ItemFigure_type_main'>
                                                            <figcaption
                                                                className={`Menu-ItemCaption Menu-ItemCaption_type_main ${
                                                                    category.Children && category.Children.length > 0
                                                                        ? 'Menu-Item-Children'
                                                                        : ''
                                                                }`}
                                                            >
                                                                {category.Name}
                                                            </figcaption>
                                                        </figure>
                                                    </a>
                                                </li>
                                            );
                                        })}
                                </ul>
                            </div>
                            {menuOpen ? (
                                <div
                                    ref={subCategoryRef}
                                    onMouseLeave={e => handleMenuCategoryClose(e)}
                                    className='Menu-SubCategoriesWrapper Menu-SubCategoriesWrapper_isVisible'
                                >
                                    <div className='Menu-SubCategoriesWrapperInner Menu-SubCategoriesWrapperInner_isVisible'>
                                        <div className='Menu-SubCategories'>
                                            <div className='Menu-SubMenu Menu-SubMenu_isVisible'>
                                                <div className='Menu-ItemList_type_subcategory'>
                                                    {currentSubCategories &&
                                                        currentSubCategories.length > 0 &&
                                                        currentSubCategories.map(subCategory => {
                                                            return (
                                                                <div key={subCategory.RecordId} className='Menu-SubItemWrapper'>
                                                                    <a href={subCategory.Url} className=' Menu-Link'>
                                                                        <figure className='Menu-ItemFigure'>
                                                                            <div className='Image Image_ratio_custom Image_imageStatus_1 Image_hasSrc Menu-Image '>
                                                                                <img
                                                                                    src={getImageUrl(
                                                                                        subCategory.Images && subCategory.Images[0].Uri
                                                                                    )}
                                                                                    alt={
                                                                                        subCategory.Images && subCategory.Images[0].AltText
                                                                                    }
                                                                                    className='Image-Image'
                                                                                    style={{ width: '100%', height: '100%' }}
                                                                                />
                                                                            </div>
                                                                            <figcaption className='Menu-ItemCaption'>
                                                                                {subCategory.Name}
                                                                            </figcaption>
                                                                        </figure>
                                                                    </a>
                                                                    <div className='Menu-SubLevelDesktop'>
                                                                        <div className='Menu-ItemList'>
                                                                            {subCategory.Children &&
                                                                                subCategory.Children.map(childrenCategory => {
                                                                                    return (
                                                                                        <a
                                                                                            key={childrenCategory.RecordId}
                                                                                            href={childrenCategory.Url}
                                                                                            className=' Menu-Link'
                                                                                        >
                                                                                            <figure className='Menu-ItemFigure'>
                                                                                                <div className='Image Image_ratio_custom Image_imageStatus_1 Image_hasSrc Menu-Image '>
                                                                                                    <img
                                                                                                        src={getImageUrl(
                                                                                                            childrenCategory.Images &&
                                                                                                                childrenCategory.Images[0]
                                                                                                                    .Uri
                                                                                                        )}
                                                                                                        alt={
                                                                                                            childrenCategory.Images &&
                                                                                                            childrenCategory.Images[0]
                                                                                                                .AltText
                                                                                                        }
                                                                                                        className='Image-Image'
                                                                                                        style={{
                                                                                                            width: '100%',
                                                                                                            height: '100%'
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                                <figcaption className='Menu-ItemCaption'>
                                                                                                    {childrenCategory.Name}
                                                                                                </figcaption>
                                                                                            </figure>
                                                                                        </a>
                                                                                    );
                                                                                })}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='Menu-Overlay Menu-Overlay_isVisible' />
                                </div>
                            ) : null}
                        </div>
                    </div> */}
                    <div className='Header-TopMenuWrapper'>
                        <div className='Header-TopMenu'>
                            <div className='Header-Contacts'>
                                <div className='CmsBlock-Wrapper CmsBlock-Wrapper_type_'>
                                    <div className='left' style={{ textAlign: 'left' }}>
                                        <a id='header-email' className='header-email' title='Email Us' href={`mailto:${config.email}`}>
                                            Mail: {config.email}
                                        </a>
                                        <a
                                            id='header-telephone'
                                            className='header-telephone'
                                            title='telephone'
                                            href={`tel:${config.telephone}`}
                                        >
                                            Telephone: {config.telephone}
                                        </a>
                                        <span style={{ color: 'rgb(0, 0, 0)' }} />
                                    </div>
                                </div>
                            </div>
                            <div className='Menu-MenuWrapper'>
                                <div className='Menu-MainCategories'>
                                    <ul aria-label='All' className='Menu-ItemList Menu-ItemList_type_main'>
                                        {categories &&
                                            categories.map(category => {
                                                return (
                                                    <li key={category.RecordId} className='Menu-Item'>
                                                        <a
                                                            href={category.Url}
                                                            className=' Menu-Link '
                                                            onMouseEnter={e => {
                                                                if (category.Children && category.Children.length > 0) {
                                                                    handleMenuOpen(e);
                                                                    getCurrentSubCategories(category);
                                                                } else {
                                                                    setMenuOpen(false);
                                                                }
                                                            }}
                                                            ref={menuRef}
                                                        >
                                                            <figure className='Menu-ItemFigure Menu-ItemFigure_type_main'>
                                                                <figcaption
                                                                    className={`Menu-ItemCaption Menu-ItemCaption_type_main ${
                                                                        category.Children && category.Children.length > 0
                                                                            ? 'Menu-Item-Children'
                                                                            : ''
                                                                    }`}
                                                                >
                                                                    {category.Name}
                                                                </figcaption>
                                                            </figure>
                                                        </a>
                                                    </li>
                                                );
                                            })}
                                    </ul>
                                </div>
                                {menuOpen ? (
                                    <div
                                        ref={subCategoryRef}
                                        onMouseLeave={e => handleMenuCategoryClose(e)}
                                        className='Menu-SubCategoriesWrapper Menu-SubCategoriesWrapper_isVisible'
                                    >
                                        <div className='Menu-SubCategoriesWrapperInner Menu-SubCategoriesWrapperInner_isVisible'>
                                            <div className='Menu-SubCategories'>
                                                <div className='Menu-SubMenu Menu-SubMenu_isVisible'>
                                                    <div className='Menu-ItemList_type_subcategory'>
                                                        {currentSubCategories &&
                                                            currentSubCategories.length > 0 &&
                                                            currentSubCategories.map(subCategory => {
                                                                return (
                                                                    <div key={subCategory.RecordId} className='Menu-SubItemWrapper'>
                                                                        <a href={subCategory.Url} className=' Menu-Link'>
                                                                            <figure className='Menu-ItemFigure'>
                                                                                <div className='Image Image_ratio_custom Image_imageStatus_1 Image_hasSrc Menu-Image '>
                                                                                    <img
                                                                                        src={getImageUrl(
                                                                                            subCategory.Images && subCategory.Images[0].Uri
                                                                                        )}
                                                                                        alt={
                                                                                            subCategory.Images &&
                                                                                            subCategory.Images[0].AltText
                                                                                        }
                                                                                        className='Image-Image'
                                                                                        style={{ width: '100%', height: '100%' }}
                                                                                    />
                                                                                </div>
                                                                                <figcaption className='Menu-ItemCaption'>
                                                                                    {subCategory.Name}
                                                                                </figcaption>
                                                                            </figure>
                                                                        </a>
                                                                        <div className='Menu-SubLevelDesktop'>
                                                                            <div className='Menu-ItemList'>
                                                                                {subCategory.Children &&
                                                                                    subCategory.Children.map(childrenCategory => {
                                                                                        return (
                                                                                            <a
                                                                                                key={childrenCategory.RecordId}
                                                                                                href={childrenCategory.Url}
                                                                                                className=' Menu-Link'
                                                                                            >
                                                                                                <figure className='Menu-ItemFigure'>
                                                                                                    <div className='Image Image_ratio_custom Image_imageStatus_1 Image_hasSrc Menu-Image '>
                                                                                                        <img
                                                                                                            src={getImageUrl(
                                                                                                                childrenCategory.Images &&
                                                                                                                    childrenCategory
                                                                                                                        .Images[0].Uri
                                                                                                            )}
                                                                                                            alt={
                                                                                                                childrenCategory.Images &&
                                                                                                                childrenCategory.Images[0]
                                                                                                                    .AltText
                                                                                                            }
                                                                                                            className='Image-Image'
                                                                                                            style={{
                                                                                                                width: '100%',
                                                                                                                height: '100%'
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <figcaption className='Menu-ItemCaption'>
                                                                                                        {childrenCategory.Name}
                                                                                                    </figcaption>
                                                                                                </figure>
                                                                                            </a>
                                                                                        );
                                                                                    })}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='Menu-Overlay Menu-Overlay_isVisible' />
                                    </div>
                                ) : null}
                            </div>
                            <div className='Header-Switcher'>
                                <div className='StoreSwitcher'>
                                    <button
                                        onClick={() => setStoreSwitcher(!openStoreSwitcher)}
                                        className={`StoreSwitcher-Title ${openStoreSwitcher ? 'StoreSwitcher-Title_isOpen' : ''} `}
                                    >
                                        {siteOption ? siteOption.text : ''}
                                    </button>
                                    <div
                                        className={`StoreSwitcher-StoreList ${openStoreSwitcher ? 'StoreSwitcher-StoreList_isOpen ' : ''} `}
                                    >
                                        {props.config.siteOptions && props.config.siteOptions.length > 0
                                            ? props.config.siteOptions.map((option, index) => {
                                                  return (
                                                      <a key={index} className='StoreItems-Item' href={option.link}>
                                                          {option.text}
                                                      </a>
                                                  );
                                              })
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </section>

            <footer className='NavigationTabs NavigationTabs_isHidden_undefined FixedElement-Bottom'>
                <nav className='NavigationTabs-Nav'>
                    <button
                        aria-label='Open my account'
                        className='NavigationTabs-Button'
                        title='My Account'
                        // ref={myAccountRef}
                        tabIndex={0}
                        id='myAccount'
                        onClick={handleOpenMyAccount}
                    >
                        {myAccountOpen ? (
                            <div className='Overlay Overlay_isVisible MyAccount_Overlay'>
                                <div className='SubLinks'>
                                    {props.config.accountLinks &&
                                        props.config.accountLinks.map((link, index) => {
                                            return (
                                                <a key={index} href={link.link?.destinationUrl}>
                                                    {link.text}
                                                </a>
                                            );
                                        })}
                                    <a href={user.signOutUrl}>Sign Out</a>
                                </div>
                            </div>
                        ) : null}
                        <div className='Header-Button Header-Button_isVisible Header-Button_type_account NavigationTabs-Icon' />
                        <span className='NavigationTabs-Label'>Account</span>
                    </button>
                    <div className='NavigationTabs-Button'>
                        <button aria-label='Home' className='NavigationTabs-Button' onClick={() => searchInputRef.current?.focus()}>
                            <div className='Header-Button Header-Button_type_search Header-Button_isVisible NavigationTabs-Icon' />
                            <span className='NavigationTabs-Label'>Search</span>
                        </button>
                    </div>
                    <button aria-label='Minicart' className='NavigationTabs-Button Header-MinicartButtonWrapper header-icons'>
                        {props.slots.mobCartIcon}
                        <a href={props.app.routes.cart.destinationUrl}>
                            <span className='NavigationTabs-Label'>Basket</span>
                        </a>
                    </button>

                    <div className='NavigationTabs-Button'>
                        <button aria-label='Go to menu and search' className='NavigationTabs-Button' onClick={toggleMenu}>
                            <div className='Header-Button Header-Button_isVisible Header-Button_type_menu NavigationTabs-Icon' />
                            <span className='NavigationTabs-Label'>Menu</span>
                        </button>
                        <div className={`menu-container ${menuContainerOpen ? 'open' : ''}`}>
                            <button className='ml-mobile-menu-close' onClick={toggleMenu}>
                                ×
                            </button>
                            <ul aria-label='All' className={`Menu-ItemList Menu-ItemList_type_main ${menuContainerOpen ? 'open' : ''}`}>
                                {categories &&
                                    categories.map(category => (
                                        <li key={category.RecordId} className='Menu-Item parent'>
                                            <div className='Menu-ItemContent'>
                                                <a
                                                    href={category.Url}
                                                    className='Menu-Link'
                                                    onClick={e => handleSubMenuToggle(category.RecordId, e)}
                                                >
                                                    <figure className='Menu-ItemFigure Menu-ItemFigure_type_main'>
                                                        <figcaption
                                                            className={`Menu-ItemCaption Menu-ItemCaption_type_main ${
                                                                category.Children && category.Children.length > 0
                                                                    ? 'Menu-Item-Children'
                                                                    : ''
                                                            }`}
                                                        >
                                                            {category.Name}
                                                        </figcaption>
                                                    </figure>
                                                </a>
                                                {category.Children && category.Children.length > 0 && (
                                                    <div className='Menu-Icon' onClick={e => handleSubMenuToggle(category.RecordId, e)}>
                                                        ▼
                                                    </div>
                                                )}
                                            </div>
                                            {currentSubMenuOpen === category.RecordId && (
                                                <SubMenuComponent
                                                    subCategories={category.Children || []}
                                                    isOpen={currentSubMenuOpen === category.RecordId}
                                                    onToggle={() => setCurrentSubMenuOpen(null)}
                                                    onSubSubMenuToggle={(subCategory, event) => handleSubSubMenuToggle(subCategory, event)}
                                                />
                                            )}
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    </div>
                </nav>
            </footer>
        </div>
    );
};
